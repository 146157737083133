import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
import '../../assets/styles/jquery.fullpage.min.css'
import '../../assets/scripts/jquery.fullpage.min.js'
$("header .nav li").eq(0).addClass("active");
$("header").addClass("header")

$(".header").hover(function(){
	if($(this).hasClass("head1")){
	}else {
		$(this).addClass("head1 active");
	}
}, function(){
	if($(this).hasClass("active")){
		$(this).removeClass("head1 active");
	}else {
		
	}
})
var swiper = new Swiper(".banner #banner-swiper", {
    observer:true,//修改swiper自己或子元素时，自动初始化swiper
    observeParents:true,//修改swiper的父元素时，自动初始化swiper
    loop:true,
    speed:2000,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    pagination: {
        el: '.banner .swiper-page',
        clickable: true,
		renderBullet: function (index, className) {
			return '<span class="' + className + '"><div>' + "</div></span>";
		},
    },
});
$(function(){
	$('#dowebok').fullpage({
		navigation:true,
		verticalCentered:false,
		css3: false,
		scrollingSpeed:800,
		slidesNavigation:false,

		anchors: ['page1', 'page2','page3','page4','page5','page6'],
		menu: '#menu',
		autoScrolling: true,
		//anchors: ['hero', 'services', 'cases', 'news','about','contact','footer']
		
		afterLoad: function(anchorLink, index)
		{
			if(index == 1)
			{
                $(".header").removeClass("head1");
			}
			if(index == 2)
			{
				$(".header").addClass("head1");
			}
			if(index == 3)
			{		
				$(".header").addClass("head1");
                //设置计数
                $.fn.countTo = function (options) {
                    options = options || {};
                    return $(this).each(function () {
                        //当前元素的选项
                        var settings = $.extend({}, $.fn.countTo.defaults, {
                            from:            $(this).data('from'),
                            to:              $(this).data('to'),
                            speed:           $(this).data('speed'),
                            refreshInterval: $(this).data('refresh-interval'),
                            decimals:        $(this).data('decimals')
                    }, options);
                    //更新值
                    var loops = Math.ceil(settings.speed / settings.refreshInterval),
                    increment = (settings.to - settings.from) / loops;
                    //更改应用和变量
                    var self = this,
                    $self = $(this),
                    loopCount = 0,
                    value = settings.from,
                    data = $self.data('countTo') || {};
                    $self.data('countTo', data);
                    //如果有间断，找到并清除
                    if (data.interval) {
                        clearInterval(data.interval);
                    };
                    data.interval = setInterval(updateTimer, settings.refreshInterval);
                    //初始化起始值
                    render(value);
                    function updateTimer() {
                        value += increment;
                        loopCount++;
                        render(value);
                        if (typeof(settings.onUpdate) == 'function') {
                            settings.onUpdate.call(self, value);
                        }
                        if (loopCount >= loops) {
                            //移出间隔
                            $self.removeData('countTo');
                            clearInterval(data.interval);
                            value = settings.to;
                            if (typeof(settings.onComplete) == 'function') {
                                settings.onComplete.call(self, value);
                            }
                        }
                    }
                    function render(value) {
                        var formattedValue = settings.formatter.call(self, value, settings);
                        $self.html(formattedValue);
                    }
                    });
                };
                $.fn.countTo.defaults={
                    from:0,               //数字开始的值
                    to:0,                 //数字结束的值
                    speed:1000,           //设置步长的时间
                    refreshInterval:100,  //隔间值
                    decimals:0,           //显示小位数
                    formatter: formatter, //渲染之前格式化
                    onUpdate:null,        //每次更新前的回调方法
                    onComplete:null       //完成更新的回调方法
                };
                function formatter(value, settings){
                    return value.toFixed(settings.decimals);
                }
                //自定义格式
                $('#count-number').data('countToOptions',{
                    formmatter:function(value, options){
                        return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
                    }
                });
                //定时器
                $('.timer').each(count);
                function count(options){
                    var $this=$(this);
                    options=$.extend({}, options||{}, $this.data('countToOptions')||{});
                    $this.countTo(options);
                }
			}
			if(index == 4)
			{	
				$(".header").addClass("head1");
			}
			if(index == 5)
			{		
				$(".header").addClass("head1");
			}
			if(index == 6)
			{
				$(".header").addClass("head1");
			}
		},
	

	});
	$(window).resize(function(){
        autoScrolling();
    });
	/*手机上不滚动*/
	function autoScrolling(){
        var $ww = $(window).width();
        if($ww < 1024){
            $.fn.fullpage.setAutoScrolling(false);
			$.fn.fullpage.setFitToSection(false);
        } else {
            $.fn.fullpage.setAutoScrolling(true);
        }
    }
	autoScrolling();
});

//解决方案
var swiper = new Swiper(".page3_bg .mySwiper", {
	slidesPerView: 4,
	spaceBetween: 10,
	// loop:true,
    speed:500,
    autoplay: {
        delay: 2000,
        disableOnInteraction: false,
    },
	scrollbar: {
        el: ".page3_bg .swiper-scrollbar",
    },
	pagination: {
		el: ".page3_bg .swiper-pagination",
		//自定义分页器样式
		type:"custom",
		renderCustom: function (swiper, current, total) {
			var dangqian = document.querySelector(".dangqian")
			var zongshu = document.querySelector(".zongshu")
			if(current<=9){
				current = "0"+current
			}else{
				current = current
			}
			if(total<=9){
				total = "0"+total
			}else{
			total = total
			}
			dangqian.innerText = current
			zongshu.innerText = total
		}
	},
	breakpoints: {
        320: { //当屏幕宽度大于等于320
        slidesPerView: 1,
        },
        768: { //当屏幕宽度大于等于768 
            slidesPerView: 3,
            spaceBetween: 10,
        },
        1280: { //当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 10,
        }
    },
});

// 新闻

$(".section5 .content .top ul li").hover(function() {
    let index = $(this).index();
    $(this).addClass("active").siblings().removeClass("active");
    $(".news-content .news").eq(index).addClass("active").siblings().removeClass("active");
})


// 视频
var video = document.getElementById('myVideo');
$(".play").click(function () { 
    $(".video").css("display","flex");
    video.play(); //播放控制
})
$(".out").click(function() {
    $(".video").hide()
    video.pause(); //暂停控制
})